import type { Key } from "@react-types/shared";
import { Dropdown } from "./Dropdown";
import { useNavigate } from "react-router-dom";
import { useGetEducationalProgramsForApp } from "@/services";
import { useGetAuthenticatedUserQuery } from "@bespeak/apollo";
import { useProgramStore } from "@/store/preferences";

const ProgramDropdown = () => {
    const { educationalPrograms } = useGetEducationalProgramsForApp();
    const { data } = useGetAuthenticatedUserQuery();

    const navigate = useNavigate();
    const user = data?.getAuthenticatedUser;
    const programStore = useProgramStore();

    const handleSelectionChange = (programId: Key) => {
        if (!user) return;

        programStore.setValue(programId);
        navigate(`/program/${programId}`);
    };

    return (
        <Dropdown
            programs={educationalPrograms}
            onChange={handleSelectionChange}
        />
    );
};

export default ProgramDropdown;
