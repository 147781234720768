import { useGetAlgoliaKeyQuery } from "@bespeak/apollo";
import { useState } from "react";
import { useKeys } from "rooks";

export function useAlgolia() {
    const { data } = useGetAlgoliaKeyQuery();
    const searchKey = data?.getAlgoliaSearchKeyForAuthenticatedUser;
    const [searchActive, setSearchActive] = useState(false);
    const handleToggleSearchActive = () => {
        setSearchActive((prev) => !prev);
    };

    const handleDisableSearchActive = () => {
        setSearchActive(false);
    };

    useKeys(["Escape"], () => {
        handleDisableSearchActive();
    });

    return {
        searchKey,
        tenantHasSearchKey: !!searchKey,
        searchActive,
        handleToggleSearchActive,
        handleDisableSearchActive,
    };
}

export default useAlgolia;
