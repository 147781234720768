import { createContext, useMemo, type ReactNode } from "react";
import { useAlgolia } from "./AlgoliaSearch.hook";
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    type ConfigureProps,
} from "react-instantsearch";
namespace AlgoliaSearchProvider {
    export interface Props {
        children: ReactNode;
        applicationID?: string;
    }
}

export const AlgoliaSearchContext = createContext<
    ReturnType<typeof useAlgolia> | undefined
>(undefined);

export const AlgoliaSearchProvider = (props: AlgoliaSearchProvider.Props) => {
    const { children, applicationID } = props;
    const algolia = useAlgolia();

    const searchClient = useMemo(() => {
        return algoliasearch(applicationID || "", algolia.searchKey || "");
    }, [applicationID, algolia.searchKey]);

    const index = import.meta.env.VITE_ALGOLIA_SEARCH_INDEX;

    const searchConfig: ConfigureProps = {
        advancedSyntax: true,
        disableTypoToleranceOnAttributes: ["shortcode"],
        queryType: "prefixNone",
        restrictSearchableAttributes: ["shortcode", "available_to"],
    };

    return (
        <AlgoliaSearchContext.Provider value={algolia}>
            {algolia.tenantHasSearchKey ? (
                <InstantSearch searchClient={searchClient} indexName={index}>
                    <Configure {...searchConfig} />
                    {children}
                </InstantSearch>
            ) : (
                children
            )}
        </AlgoliaSearchContext.Provider>
    );
};
